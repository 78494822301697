import React, { useState, useCallback, useRef, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import bgTrans from '../assets/images/blobs-wc.svg'
import bgTrans2 from '../assets/images/blobs-wc.svg'
import GetSt from '../assets/images/getting-started.svg'
import CollectionList from '../assets/images/collection-list.svg'
import perfectCode from '../assets/images/Technical SEO.svg'
import uploadCircle from '../assets/images/PWA Ready.svg'
import platformChoise from '../assets/images/Responsive Design.svg'


import logo from '../assets/images/logo.webp'
import loadable from "@loadable/component"
import AnchorLink from 'react-anchor-link-smooth-scroll'

import work1 from "../assets/images/work_plus.png";
import work2 from "../assets/images/work_varvaridis.png";
import work3 from "../assets/images/work_next_you_nutrition.png";
import work4 from "../assets/images/work_stegi.png";
import work5 from "../assets/images/work_ammos.png";
import work6 from "../assets/images/work_karanikolas.png";

import core1 from "../assets/images/High Performance Iocn.png";
import core2 from "../assets/images/Security Icon.png";
import core3 from "../assets/images/Accessibility Icon.png";
import core4 from "../assets/images/Expandability Icon.png";

import ReactPlayer from 'react-player'
// import ContactPromo from "../components/Promo/ContactPromo"

import { StaticImage } from "gatsby-plugin-image"

const ContactPromo = loadable(
    () => import("../components/Promo/ContactWebsitePromo")
)

// const VideoPromo = loadable(
//     () => import("../components/Promo/Video")
// )



const WebsitePromoTemplate = (props) => {
    const ref = useRef()
    const [inView, setInView] = useState(false)
    const [faqActive, setFaqActive] = useState(0)
    const [videoOn, setVideoOn] = useState(false)
    const [videoPlaying, setVideoPlaying] = useState(false)



    let data = props.data.wpgraphql;

    const handleScroll = useCallback((data) => {
        if (window.pageYOffset > 2500) {
            setInView(true)
        }
    }, [])

    useEffect(() => {
        document.addEventListener("scroll", handleScroll)

        return () => document.removeEventListener("scroll", handleScroll);
    }, [handleScroll])

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)} isPromo={true} >
            {/* <Navbar header={props.pageContext.headerMenu} isRelative={true}  lang={props.pageContext.language} /> */}
            <div className="wc-bg-m">
                <img src={bgTrans2} className="wc-bg-trans" style={{ width: "515px", height: "440px" }} />

                <header>
                    <nav>
                        <div className="d-flex justify-content-center py-4">
                            <Link to="/">
                                <img id="nav-logo" importance="high" src={logo} className={"w3-logo"} alt="W3Vitals logo" />
                            </Link>
                            {/* <StaticImage
                                // className="m-auto py-2"
                                src="../assets/images/logo.png"
                                alt="W3Vitals logo"
                                placeholder="none"
                                loading={"eager"}
                                quality={90}
                                formats={["auto", "webp"]}
                                className="w3-logo"
                                width={120}
                                layout="constrained"
                            /> */}
                        </div>
                    </nav>
                </header>
                <section className="container" style={{ paddingTop: "1rem", paddingBottom: "5rem" }}>
                    <div className="mx-auto mt-12 d-flex align-items-center flex-wrap">
                        <div className="col-12 col-md-8">
                            <h1 className="cw-h1">
                                Δημιουργία Ιστοσελίδας
                            </h1>
                            <p className="pt-4 cw-p" style={{ maxWidth: "700px", marginBottom: "0" }}>
                                Θέλεις μια ιστοσελίδα νέας γενιάς με υψηλή απόδοση, εύκολη διαχείριση και μεγάλη ευελιξία; Εμπιστεύσου την W3Vitals για δημιουργία ιστοσελίδας με όλα τα σύγχρονα feature!
                            </p>

                            <div className="d-flex cw-bt-c">
                                <AnchorLink href='#contact-section' className="es-bttn es-bttn-over">Επικοινωνία</AnchorLink>
                                <AnchorLink href='#details-section' className="es-bttn-e es-bttn-over">Περισσότερα</AnchorLink>
                            </div>
                            <ul className="cw-ul">
                                <li>Responsive σχεδίαση & φιλικό προς τον χρήστη interface</li>
                                <li>Βελτιστοποίηση ως προς τις μηχανές αναζήτησης (SEO)</li>
                                <li>Υψηλή απόδοση στα Core Web Vitals της Google</li>
                            </ul>
                        </div>
                        <div className="si-conts col-12 col-md-4">
                            <div className="es-rel cw-rel" >
                                {/* <picture>
                                <source media="(max-width: 450px)" srcset={eshopIn400} importance="high" />
                                <source media="(min-width: 451px)" srcset={eshopIn} importance="high" />
                                <img src={eshopIn} className="es-in-img" alt="Δημιουργία Eshop" />
                            </picture> */}
                                <div className={`${!videoOn ? "logo-overlap-v" : "d-none"}`} style={{cursor: "pointer"}} onClick={() => setVideoPlaying(true)}>
                                    <StaticImage
                                        // className="m-auto py-2"
                                        src="../assets/images/Square Logo.jpg"
                                        alt="Δημιουργία Eshop"
                                        placeholder="none"
                                        loading={"eager"}
                                        formats={["auto", "webp"]}
                                        quality={90}
                                        width={374}
                                        layout="constrained"
                                    />
                                </div>

                                <ReactPlayer
                                    className='react-player react-player-on-mobile'
                                    url='https://admin.w3vitals.com/wp-content/uploads/2022/07/W3Vitals-landing-page-video.mp4'
                                    width='100%'
                                    height='100%'
                                    playIcon={<button>Play</button>}
                                    controls={true}
                                    onStart={ () => setVideoOn(true)}
                                    playing={videoPlaying}

                                />

                                <StaticImage
                                    // className="m-auto py-2"
                                    src="../assets/images/iphone-mockup.png"
                                    alt="Δημιουργία Eshop"
                                    placeholder="none"
                                    loading={"eager"}
                                    formats={["auto", "webp"]}
                                    quality={90}
                                    width={374}
                                    layout="constrained"
                                />
                            </div>
                        </div>
                        <img src={bgTrans} className="wc-bg-trans" style={{ width: "678", height: "634" }} />
                    </div>
                    <div className="mx-auto d-flex flex-wrap align-items-center" style={{ marginTop: "3rem" }}>
                        <div className="col-6 col-md-3 pt-4">
                            <div className="cw-i-points">
                                <img src={core1} width="80" />
                                <div><p>Υψηλή απόδοση</p></div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 pt-4">
                            <div className="cw-i-points">
                                <img src={core4} width="80" />
                                <div><p>Επεκτασιμότητα</p></div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 pt-4">
                            <div className="cw-i-points">
                                <img src={core2} width="80" />
                                <div><p>Μέγιστη ασφάλεια</p></div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 pt-4">
                            <div className="cw-i-points">
                                <img src={core3} width="80" />
                                <div><p>Προσβασιμότητα</p></div>
                            </div>
                        </div>

                    </div>

                </section>

                <section className="container cw-section" style={{ position: "relative" }} id="details-section">
                    <div className="cw-tt-cont">
                        <h2>
                            Δημιουργία Ιστοσελίδας Νέας Γενιάς
                        </h2>
                        <p>Έχεις κουραστεί απ’ την αργή, δυσλειτουργική κι απαρχαιωμένη ιστοσελίδα σου και θέλεις να την αναβαθμίσεις; Ψάχνεις την καλύτερη δυνατή πρόταση για το καινούργιο website της επιχείρησής σου; Η cloud-based λύση μας είναι αυτό που χρειάζεσαι!</p>
                    </div>
                    <div className="col-12 col-md-6 my-mt d-block d-md-none">
                        <StaticImage
                            // className="m-auto py-2"
                            src="../assets/images/branding.png"
                            alt="Δημιουργία eshop woocommerce"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            loading={"lazy"}
                            quality={100}
                            width={570}
                            layout="constrained"
                        />
                        {/* <img src={codeEditor} /> */}
                    </div>
                    <div className="d-flex align-items-center" style={{ marginTop: "4rem", flexWrap: "wrap" }}>
                        <div className="col-12 col-md-6">
                            <p className="es-alt-p">
                                Συνδυάζοντας τεχνολογίες αιχμής (React, Gatsby) με τη σταθερότητα ενός σωστά δομημένου CMS, δημιουργούμε ιστοσελίδες που ξεχωρίζουν για την υψηλή τους απόδοση, την εύκολη διαχείρισή τους και τα συγκριτικά πλεονεκτήματα που προσφέρουν.
                            </p>
                        </div>
                        <div className="col-12 col-md-6 my-mt d-none d-md-block">
                            <StaticImage
                                // className="m-auto py-2"
                                src="../assets/images/branding.jpg"
                                alt="Δημιουργία eshop woocommerce"
                                placeholder="none"
                                formats={["auto", "webp"]}
                                loading={"lazy"}
                                quality={100}
                                width={570}
                                layout="constrained"
                            />
                            {/* <img src={codeEditor} /> */}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ marginTop: "2rem", flexWrap: "wrap" }}>
                        <div className="col-12 col-md-4">
                            <img src={perfectCode} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3">Responsive Design</h3>
                            <p className="es-alt-p">Δημιουργία ιστοσελίδας με responsive σχεδίαση για βέλτιστη προβολή σε κάθε μέγεθος οθόνης που εξασφαλίζει εξαιρετική εμπειρία χρήσης και πλοήγησης.</p>
                        </div>
                        <div className="col-12 my-mt col-md-4">
                            <img src={platformChoise} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3">Technical SEO</h3>
                            <p className="es-alt-p">Δημιουργία ιστοσελίδας βελτιστοποιημένης ως προς τις μηχανές αναζήτησης για καλύτερο indexability και υψηλότερες θέσεις στα αποτελέσματα των Google αναζητήσεων.</p>
                        </div>
                        <div className="col-12 my-mt col-md-4">
                            <img src={uploadCircle} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3">PWA Ready</h3>
                            <p className="es-alt-p">Δημιουργία ιστοσελίδας με βελτιστοποίηση PWA που επιτρέπει την πλοήγηση εκτός σύνδεσης και υποστηρίζει την αποστολή εγγενών ειδοποιήσεων.</p>
                        </div>

                    </div>
                </section>

                <section className="cw-section" style={{ position: "relative", marginBottom: "4rem" }} id="details-section">
                    <div className="container">
                        <div className="cw-tt-cont">
                            <h2>
                                Ενδεικτικά Case Studies
                            </h2>
                            <p>Ρίξε μια ματιά σε κάποια ενδεικτικά παραδείγματα από ιστοσελίδες που έχουμε δημιουργήσει μέχρι στιγμής για πελάτες μας.</p>
                        </div>
                    </div>
                    <div style={{ minHeight: "200px" }}>
                        <div className="d-flex flex-wrap" style={{ overflow: "hidden" }} >
                            <div className="col-6 col-lg-4">
                                <a href="https://w3vitals.com/case-studies/web-development-case-plusmedical-gr/" target={"_blank"} className="rp-item">
                                    <div className="rp-main-image-container">
                                        <div className="rp-main-perspective-image"></div>
                                        <img className="rp-main-image" src="https://admin.w3vitals.com/wp-content/uploads/2022/06/Plus-Medical_Cover.jpg" />
                                    </div>
                                    <div className="rp-repspective-wrap">
                                        <img className="rp-image-perspective" src={work1} />

                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-lg-4">
                                <a href="https://w3vitals.com/case-studies/web-development-case-kleidaras-varvaridis-gr/" target={"_blank"} className="rp-item">
                                    <div className="rp-main-image-container">
                                        <div className="rp-main-perspective-image"></div>
                                        <img className="rp-main-image" src="https://admin.w3vitals.com/wp-content/uploads/2022/06/Varvaridis_Cover.jpg" />
                                    </div>
                                    <div className="rp-repspective-wrap">
                                        <img className="rp-image-perspective" src={work2} />

                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-lg-4 ">
                                <a href="https://w3vitals.com/case-studies/web-development-case-nextyounutrition-gr/" target={"_blank"} className="rp-item">
                                    <div className="rp-main-image-container">
                                        <div className="rp-main-perspective-image"></div>
                                        <img className="rp-main-image" src="https://admin.w3vitals.com/wp-content/uploads/2020/05/Next-You-Nutrition_Cover.jpg" />
                                    </div>
                                    <div className="rp-repspective-wrap">
                                        <img className="rp-image-perspective" src={work3} />

                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-lg-4 ">
                                <a href="https://w3vitals.com/case-studies/web-development-case-stegi-therapeion-gr/" target={"_blank"} className="rp-item">
                                    <div className="rp-main-image-container">
                                        <div className="rp-main-perspective-image"></div>
                                        <img className="rp-main-image" src="https://admin.w3vitals.com/wp-content/uploads/2020/05/Stegi_Cover.jpg" />
                                    </div>
                                    <div className="rp-repspective-wrap">
                                        <img className="rp-image-perspective" src={work4} />

                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-lg-4 ">
                                <a href="https://w3vitals.com/case-studies/web-development-case-ammosgreece-gr/" target={"_blank"} className="rp-item">
                                    <div className="rp-main-image-container">
                                        <div className="rp-main-perspective-image"></div>
                                        <img className="rp-main-image" src="https://admin.w3vitals.com/wp-content/uploads/2022/07/Ammos_Cover.jpg" />
                                    </div>
                                    <div className="rp-repspective-wrap">
                                        <img className="rp-image-perspective" src={work5} />

                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-lg-4 ">
                                <a href="https://w3vitals.com/case-studies/web-development-case-karanikolascon-gr/" target={"_blank"} className="rp-item">
                                    <div className="rp-main-image-container">
                                        <div className="rp-main-perspective-image"></div>
                                        <img className="rp-main-image" src="https://admin.w3vitals.com/wp-content/uploads/2022/06/Karanikolas_Cover.jpg" />
                                    </div>
                                    <div className="rp-repspective-wrap">
                                        <img className="rp-image-perspective" src={work6} />

                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="cw-section">
                    <div className="cw-s-trans" />
                    <div className="container mx-auto cw-m-cont">
                        <div className="cw-tt-cont">
                            <h2>
                                Συχνές Ερωτήσεις
                            </h2>
                            <p>Παρακάτω θα βρεις τις απαντήσεις μας σε κάποιες απ’ τις πιο συχνές ερωτήσεις για τη δημιουργία ιστοσελίδας.</p>
                        </div>

                        <div className="d-flex flex-wrap">
                            <div className="col-12 col-md-4 pb-4">
                                <div className={`d-flex cw-pli ${faqActive === 0 && 'factive'}`} onClick={() => setFaqActive(0)}>
                                    <div>
                                        <img src={GetSt} width={16} style={{ filter: "invert(1)" }} />
                                    </div>
                                    <p className="pl-2">Χρόνος Δημιουργίας Ιστοσελίδας</p>
                                </div>
                                <div className={`d-flex cw-pli ${faqActive === 1 && 'factive'}`} onClick={() => setFaqActive(1)}>
                                    <div>
                                        <img src={GetSt} width={16} style={{ filter: "invert(1)" }} />
                                    </div>
                                    <p className="pl-2">Κόστος Δημιουργίας Ιστοσελίδας</p>
                                </div>
                                <div className={`d-flex cw-pli ${faqActive === 2 && 'factive'}`} onClick={() => setFaqActive(2)}>
                                    <div>
                                        <img src={GetSt} width={16} style={{ filter: "invert(1)" }} />
                                    </div>
                                    <p className="pl-2">Δημιουργία Ιστοσελίδας με Blog</p>
                                </div>
                                <div className={`d-flex cw-pli ${faqActive === 3 && 'factive'}`} onClick={() => setFaqActive(3)}>
                                    <div>
                                        <img src={GetSt} width={16} style={{ filter: "invert(1)" }} />
                                    </div>
                                    <p className="pl-2">Σύστημα Διαχείρισης Ραντεβού</p>
                                </div>
                                <div className={`d-flex cw-pli ${faqActive === 4 && 'factive'}`} onClick={() => setFaqActive(4)}>
                                    <div>
                                        <img src={GetSt} width={16} style={{ filter: "invert(1)" }} />
                                    </div>
                                    <p className="pl-2">Δημιουργία Ιστοσελίδας WCAG 2.0</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-8 cw-ptext">
                                {faqActive === 0 && (
                                    <div>
                                        <p>Αναρωτιέσαι πόσος χρόνος χρειάζεται για να δημιουργηθεί μια ιστοσελίδα;</p>
                                        <p>Η απάντηση εξαρτάται από διάφορους παράγοντες, όπως το είδος της ιστοσελίδας, την περιπλοκότητά της και τις τεχνολογίες που θα ενσωματωθούν στην αρχιτεκτονική της.</p>
                                        <p>Θα μπορούμε να σου δώσουμε μια εκτίμηση χρόνου υλοποίησης στην πρώτη μας συνάντηση, αφού ακούσουμε τις ανάγκες σου.</p>
                                    </div>
                                )}
                                {faqActive === 1 && (
                                    <div>
                                        <p>Αναρωτιέσαι πόσο κοστίζει η δημιουργία ιστοσελίδας;</p>
                                        <p>Το κόστος για την κατασκευή ιστοσελίδας εξαρτάται σε μεγάλο βαθμό απ’ τις ιδιαιτερότητες του εκάστοτε project.</p>
                                        <p>Ο τύπος της ιστοσελίδας, το design της, οι δυνατότητες που προσφέρει και οι τεχνολογίες που ενσωματώνει είναι κάποιοι απ’ τους παράγοντες που διαμορφώνουν το τελικό κόστος.</p>
                                    </div>
                                )}

                                {faqActive === 2 && (
                                    <div>
                                        <p>Θέλεις η ιστοσελίδα σου να περιλαμβάνει και μία ενότητα blog; Κανένα πρόβλημα!</p>
                                        <p>Η ανάρτηση τακτικού περιεχομένου σε ένα εταιρικό blog ενισχύει την οργανική επισκεψιμότητα στην ιστοσελίδα και δημιουργεί προστιθέμενη αξία για τους χρήστες.</p>
                                        <p>Γι’ αυτό, ενθαρρύνουμε τους πελάτες μας να περιλαμβάνουν ενότητα blog κατά τη δημιουργία της ιστοσελίδας τους.</p>
                                        <p>Η πλειονότητα των ιστοσελίδων που κατασκευάζουμε στηρίζονται σε μια headless αρχιτεκτονική με βάση το WordPress CMS για εύκολη διαχείριση περιεχομένου.</p>
                                    </div>
                                )}

                                {faqActive === 3 && (
                                    <div>
                                        <p>Θέλεις οι πελάτες σου να κλείνουν ραντεβού αυτόματα μέσα απ’ την ιστοσελίδα σου; Κανένα πρόβλημα!</p>
                                        <p>Αναλαμβάνουμε τη δημιουργία ιστοσελίδας με web app για ραντεβού, όπου οι χρήστες θα μπορούν με ευκολία να κλείσουν ραντεβού τις διαθέσιμες ώρες που έχεις ορίσει.</p>
                                    </div>
                                )}

                                {faqActive === 4 && (
                                    <div>
                                        <p>Διεκδικείς επιδότηση μέσω προγραμμάτων ΕΣΠΑ και χρειάζεσαι μια ιστοσελίδα φιλική προς άτομα με ειδικές ανάγκες;</p>
                                        <p>Αναλαμβάνουμε τη δημιουργία ιστοσελίδων που διευκολύνουν την πρόσβαση σε άτομα με προβλήματα όρασης, ακοής κι άλλες ιδιαιτερότητες.</p>
                                        <p>Εφόσον μας το ζητήσεις, εγγυόμαστε ότι η ιστοσελίδα μας θα πληροί όλα τα standard του προτύπου WCAG 2.0, ώστε να μπορεί να επιδοτηθεί μέσω ΕΣΠΑ.</p>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                </section>

                <section id="contact-section" className="cw-section" style={{ paddingTop: "0", paddingBottom: 0 }}>
                    <img src={bgTrans2} className="wc-bg-trans" style={{ width: "515px", height: "440px" }} />
                    <div className="cw-s-trans" />
                    {inView && (
                        <ContactPromo
                            key={""}
                            circleShape1={props.data.circleShape1}
                            originator={"Website-Promotion"}
                        />
                    )}
                </section>

            </div>


            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    )
}

export default WebsitePromoTemplate

export const pageQuery = graphql`
    query GET_WEBSITE_PROMO_PAGE($id: ID! , $footerTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
            }

            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
        circleShape1: file(relativePath: {eq: "shape/circle-shape1.png" }) {
            childImageSharp {
                fixed(width: 350 , height: 386){
                    base64
                    width
                    height
                    src
                    srcSet
                }
            }
        }       
    }
 `